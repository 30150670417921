<template>
  <div class="container py-12">
    <div>
      <component
        v-if="payment_type == 'PaymentApplicationFee'"
        :is="payment_type"
        :job_id="String(data.job_id)"
        :return_url="return_url"
        @success="onPaymentIntentCreated"
      ></component>
      <component
        v-else
        :is="payment_type"
        :return_url="'payment'"
        @success="onPaymentIntentCreated"
      ></component>
    </div>
  </div>
</template>

<script>
  import { STRIPE } from '@/api';

  // Payment Type Components
  import PaymentToken from '@/components/payment/Payments/PaymentToken.vue';
  import PaymentApplicationFee from '@/components/payment/Payments/PaymentApplicationFee.vue';

  const paymentBtnStatus = [
    'Confirm Payment',
    'Processing Payment...',
    'Payment Succeed'
  ];
  export default {
    name: 'PaymentGateway',
    components: {
      PaymentToken,
      PaymentApplicationFee
    },
    props: {
      payment_type: {
        type: String
      },
      return_url: {
        type: String
      },
      data: {
        type: Object
      }
    },
    data: () => ({
      iframeUrl: null
    }),
    computed: {
      paymentBtn() {
        return paymentBtnStatus[this.payment_status];
      }
    },
    watch: {},
    methods: {
      async onPaymentIntentCreated(payload) {
        const url = await STRIPE.loadStripe(payload);
        this.$router.push('/');
        window.open(url, '_blank');
      }
    }
  };
</script>

<style scoped>
  #error-message {
    color: red;
    font-size: 1rem;
  }
  .payment-frame {
    width: 100%;
    border: 0;
  }
</style>
