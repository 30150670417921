<template>
  <v-col cols="12" class="topup-container">
    <v-col cols="6" class="text-center">
      <v-row> Number of credit(s) you wish to purchase. </v-row>
      <v-row class="form_field">
        <v-col cols="1">
          <CustomRoundButton
            @click="
              () => {
                onAmountChange(-1);
              }
            "
            :disabled="field_status.disabled"
            :size="20"
            >{{ '-' }}</CustomRoundButton
          >
        </v-col>
        <v-col cols="10">
          <CustomInput
            v-model="form.data[`${form.field.name}`]"
            v-bind="form.field"
            :dark="true"
            :outlined="true"
            :disabled="field_status.disabled"
            @blur="
              () => {
                onInputChange();
              }
            "
          ></CustomInput>
        </v-col>
        <v-col cols="1"
          ><CustomRoundButton
            @click="
              () => {
                onAmountChange(1);
              }
            "
            :disabled="field_status.disabled"
            :size="20"
            >{{ '+' }}</CustomRoundButton
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col class="text-center">
          Total Price: <b>SGD {{ form.data[`${form.field.name}`] * 3 }}.00</b>
        </v-col>
      </v-row>
      <v-row v-if="field_status.if">
        <v-col>
          <button
            class="btn1 px-10 py-2 custom outline v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"
            @click="backToPreviousPage"
            :disabled="field_status.disabled"
          >
            Cancel
          </button>
        </v-col>
        <v-col>
          <button
            class="btn1 px-10 py-2"
            @click="onTopupSubmit"
            :disabled="field_status.disabled"
          >
            {{ btnText }}
          </button>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
  import { STRIPE } from '@/api';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import CustomRoundButton from '@/components/inputs/CustomRoundButton.vue';

  //   Maybe put it in another file?
  const btnStatus = ['Proceed', 'Proceeding...'];

  export default {
    name: 'PaymentToken',
    components: {
      CustomInput,
      CustomRoundButton
    },
    props: {
      return_url: {
        type: String
      }
    },
    emits: ['success'],
    watch: {},
    computed: {
      btnText() {
        return btnStatus[this.status];
      },
      field_status() {
        switch (this.status) {
          case 1:
            return { if: true, disabled: true };
          case 2:
            return { if: false, disabled: true };
          default:
            return { if: true, disabled: false };
        }
      }
    },
    data: () => ({
      status: 0,
      form: {
        data: {
          amount: 1
        },
        field: {
          id: 'fees',
          name: 'amount',
          placeholder: 'Credit Amount',
          label: 'Credit Amount',
          type: 'number',
          rules: [],
          column: { cols: '12', md: '6' },
          min: 1,
          'hide-details': 'auto',
          required: false
        }
      }
    }),
    methods: {
      async backToPreviousPage() {
        this.$router.go(-1);
      },
      onInputChange() {
        this.onAmountChange();
      },
      onAmountChange(amount = 0) {
        const newVal = Number(this.form.data.amount) + Number(amount);

        if (newVal < 1 || isNaN(this.form.data.amount)) {
          this.form.data.amount = 1;
          return;
        }

        this.form.data.amount = newVal;
      },
      async onTopupSubmit() {
        try {
          this.status = 1;
          let payload = {
            name: 'upload_token',
            item_type: 'upload_token',
            value: this.form.data.amount
          };

          const paymentIntent = await STRIPE.createPaymentIntent(payload);
          const clientSecret = paymentIntent?.data?.client_secret;
          const returnUrl = await STRIPE.getUrl(this.$props.return_url);
          const result = {
            clientSecret,
            return_url: returnUrl
          };

          this.$emit('success', result);
          this.status = 2;
        } catch (e) {
          this.status = 0;
        }
      }
    }
  };
</script>
<style scoped>
  .topup-container {
    display: flex;
    justify-content: center;
  }
  .form_field > .col {
    align-self: center;
  }
</style>
