<template>
  <PaymentGateway
    v-if="payment_type"
    :payment_type="payment_type"
    :return_url="return_url"
    :data="data"
  />
</template>
<script>
  import PaymentGateway from '@/components/payment/PaymentGateway.vue';

  export default {
    name: 'PaymentGatewayPage',
    components: {
      PaymentGateway
    },
    data: () => ({
      payment_type: null,
      return_url: null,
      data: {}
    }),
    mounted() {
      const { type, success_url, ...data } = this.$route.query;

      switch (type) {
        case 'token':
          this.payment_type = 'PaymentToken';
          break;
        case 'application_fee':
          this.payment_type = 'PaymentApplicationFee';
          break;
      }

      this.return_url = success_url;
      this.data = data;
    }
  };
</script>
<style scoped></style>
