<template>
  <div
    class="button_container"
    :style="{ width: `${size}px`, height: `${size}px` }"
    @click="onClick"
  >
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'CustomRoundButton',
    emits: ['click'],
    props: {
      label: {
        type: String,
        default: ''
      },
      size: {
        type: Number,
        default: 30
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onClick() {
        if (this.disabled == false) this.$emit('click');
      }
    }
  };
</script>
<style scoped>
  .button_container {
    color: black;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
</style>
