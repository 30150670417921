import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{staticClass:"topup-container",attrs:{"cols":"12"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"6"}},[_c(VRow,[_vm._v(" Number of credit(s) you wish to purchase. ")]),_c(VRow,{staticClass:"form_field"},[_c(VCol,{attrs:{"cols":"1"}},[_c('CustomRoundButton',{attrs:{"disabled":_vm.field_status.disabled,"size":20},on:{"click":function () {
              _vm.onAmountChange(-1);
            }}},[_vm._v(_vm._s('-'))])],1),_c(VCol,{attrs:{"cols":"10"}},[_c('CustomInput',_vm._b({attrs:{"dark":true,"outlined":true,"disabled":_vm.field_status.disabled},on:{"blur":function () {
              _vm.onInputChange();
            }},model:{value:(_vm.form.data[("" + (_vm.form.field.name))]),callback:function ($$v) {_vm.$set(_vm.form.data, ("" + (_vm.form.field.name)), $$v)},expression:"form.data[`${form.field.name}`]"}},'CustomInput',_vm.form.field,false))],1),_c(VCol,{attrs:{"cols":"1"}},[_c('CustomRoundButton',{attrs:{"disabled":_vm.field_status.disabled,"size":20},on:{"click":function () {
              _vm.onAmountChange(1);
            }}},[_vm._v(_vm._s('+'))])],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-center"},[_vm._v(" Total Price: "),_c('b',[_vm._v("SGD "+_vm._s(_vm.form.data[("" + (_vm.form.field.name))] * 3)+".00")])])],1),(_vm.field_status.if)?_c(VRow,[_c(VCol,[_c('button',{staticClass:"btn1 px-10 py-2 custom outline v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default",attrs:{"disabled":_vm.field_status.disabled},on:{"click":_vm.backToPreviousPage}},[_vm._v(" Cancel ")])]),_c(VCol,[_c('button',{staticClass:"btn1 px-10 py-2",attrs:{"disabled":_vm.field_status.disabled},on:{"click":_vm.onTopupSubmit}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }