<template>
  <v-col cols="12" class="topup-container">
    <v-col cols="6" class="text-center">
      <v-row> Price for the application fee (SGD)</v-row>
      <v-row class="form_field">
        <v-col cols="1"> </v-col>
        <v-col cols="10">
          <CustomMoneyInput v-model="form.data[`${form.field.name}`]" />
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
      <v-row v-if="field_status.if">
        <v-col>
          <button
            class="btn1 px-10 py-2 custom outline v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"
            @click="backToPreviousPage"
            :disabled="field_status.disabled"
          >
            Cancel
          </button>
        </v-col>
        <v-col>
          <button
            class="btn1 px-10 py-2"
            @click="onTopupSubmit"
            :disabled="field_status.disabled"
          >
            {{ btnText }}
          </button>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
  import { STRIPE } from '@/api';
  import {
    JOB_ORGANIZER_INITIAL_GET_CASTING_JOB_BY_ID_STATE,
    JOB_ORGANIZER_GET_CASTING_JOB_BY_ID
  } from '@/store/job-organizer.module';

  import CustomMoneyInput from '@/components/inputs/CustomMoneyInput.vue';

  //   Maybe put it in another file?
  const btnStatus = ['Proceed', 'Proceeding...'];

  export default {
    name: 'PaymentToken',
    components: {
      CustomMoneyInput
    },
    emits: ['success'],
    watch: {
      getJobByIdComplete() {
        let response = this.$store.state.jobOrganizer.getCastingJobById;
        if (response.complete) {
          this.importJobObj(response);
        }
      }
    },
    props: {
      return_url: {
        type: String
      },
      job_id: {
        type: String,
        required: true
      }
    },
    computed: {
      getJobByIdComplete() {
        return this.$store.state.jobOrganizer.getCastingJobById.complete;
      },
      btnText() {
        return btnStatus[this.status];
      },
      field_status() {
        switch (this.status) {
          case 1:
            return { if: true, disabled: true };
          case 2:
            return { if: false, disabled: true };
          default:
            return { if: true, disabled: false };
        }
      }
    },
    data: () => ({
      status: 0,
      form: {
        data: {
          amount: 1
        },
        field: {
          id: 'fees',
          name: 'amount',
          placeholder: 'Application Fee (SGD)',
          label: 'Application Fee (SGD)',
          type: 'number',
          rules: [],
          column: { cols: '12', md: '6' },
          min: 1,
          'hide-details': 'auto',
          required: false
        }
      },

      jobObj: {
        project_type_id: {
          name: null,
          datasetId: null
        },
        casting_roles: []
      }
    }),
    async mounted() {
      this.$store.dispatch(JOB_ORGANIZER_GET_CASTING_JOB_BY_ID, {
        id: this.$props.job_id
      });
    },
    methods: {
      async backToPreviousPage() {
        this.$router.go(-1);
      },
      onInputChange() {
        this.onAmountChange();
      },
      onAmountChange(amount = 0) {
        const newVal = Number(this.form.data.amount) + Number(amount);

        if (newVal < 1 || isNaN(this.form.data.amount)) {
          this.form.data.amount = 1;
          return;
        }

        this.form.data.amount = newVal;
      },
      async onTopupSubmit() {
        try {
          this.status = 1;
          let payload = {
            name: 'application_fee',
            item_type: 'application_fee',
            value: this.form.data.amount,

            // Rest Payloads
            casting_job_id: Number(this.$props.job_id)
          };

          const paymentIntent = await STRIPE.createPaymentIntent(payload);
          const clientSecret = paymentIntent?.data?.client_secret;
          const returnUrl = await STRIPE.getUrl(this.$props.return_url);
          const result = {
            clientSecret,
            return_url: returnUrl
          };

          this.$emit('success', result);
          this.status = 2;
        } catch (e) {
          this.status = 0;
        }
      },

      // LOAD DATA
      importJobObj(response) {
        this.form.data.amount = response.data.application_fees;

        this.getJobByIdCompleteAction();
      },
      getJobByIdCompleteAction() {
        this.initialGetJobByIdState();
      },
      initialGetJobByIdState() {
        this.$store.dispatch(JOB_ORGANIZER_INITIAL_GET_CASTING_JOB_BY_ID_STATE);
      }
    }
  };
</script>
<style scoped>
  .topup-container {
    display: flex;
    justify-content: center;
  }
  .form_field > .col {
    align-self: center;
  }
</style>
